import { Injectable } from "@angular/core";
import { UserManager, UserManagerSettings, User } from "oidc-client";
import { StartupService } from "../services/startup.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/retry";
import { BehaviorSubject } from "rxjs";
import { OPERATIONS } from "./auth.service.constants";

const port =
  window.location.port && window.location.port !== "80"
    ? `:${window.location.port}`
    : "";
const baseUrl = `${location.protocol}//${location.hostname}${port}`;

export function getClientSettings(
  startup: StartupService
): UserManagerSettings {
  return {
    authority: startup.authAPIUrl,
    client_id: "rating-web",
    redirect_uri: `${baseUrl}/auth-callback`,
    post_logout_redirect_uri: baseUrl,
    response_type: "id_token token",
    scope: "openid profile email",
    silent_redirect_uri: `${baseUrl}/auth-callback`,
    filterProtocolClaims: true,
    loadUserInfo: true,
  };
}

@Injectable()
export class AuthService {
  private manager = new UserManager(getClientSettings(this.startup));
  private _tokenTime: any;
  private _accessTime: any;
  showHeader: boolean;

  public $loggedIn: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public $enterpriseSelected: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public OPERATIONS = OPERATIONS;

  constructor(
    private startup: StartupService,
    private http: HttpClient,
    private router: Router,
    private startupService: StartupService
  ) {
    this.manager.getUser().then(
      (user) => {
        this.authUser = user;
      },
      (err) => {
        console.log("manager.getUser failed", err);
      }
    );

    const _local = this;
    this.manager.events.addAccessTokenExpiring(() => {
      _local.updateToken();
    });

    this.manager.events.addAccessTokenExpired(() => {
      _local.logout();
    });

    this.manager.events.addUserSignedOut(() => {
      this.manager.removeUser();
      this.authUser = null;
      this.startAuthentication();
    });

    if (!sessionStorage.getItem("access_time")) {
      this.setAccessTime();
    } else {
      this._accessTime = new Date(
        JSON.parse(sessionStorage.getItem("access_time"))
      );
    }
  }

  get isLoggedIn(): boolean {
    return this.authUser != null && !this.authUser.expired;
  }

  public hasOperation(operation: string): boolean {
    // make sure we perform case insensitive checks here
    const operations = this.BlueShipUser.operations.map(o => `${o}`.trim().toLowerCase());
    operation = `${operation}`.trim().toLowerCase();
    return operations ? operations.indexOf(operation) > -1 : false;
  }

  getClaims(): any {
    return this.authUser.profile;
  }

  getAuthorizationHeaderValue(): string {
    return this.authUser
      ? `${this.authUser.token_type} ${this.authUser.access_token}`
      : "";
  }

  startAuthentication(returnUrl?: string): void {
    this.manager.signinRedirect({ state: { returnUrl: returnUrl } }).then(
      () => {},
      (err) => {
        console.log("signinRedirect failed", err);
      }
    );
  }

  completeAuthentication(): void {
    this.manager.signinRedirectCallback().then(
      (user) => {
        this.authUser = user;
        const returnUrl = user && user.state ? user.state.returnUrl : null;
        this.getUserDetails().subscribe(
          (resp) => {
            if (!resp) {
              alert("no user exists in db");
            } else {
              this.setBlueshipUser(resp);
              this.$loggedIn.next(resp.enterpriseID);
              this.Branding = resp.branding;
              const selectedID =
                this.getSelectedEnterpriseID(resp.userID) || resp.enterpriseID;
              this.getEnterpriseProfile(selectedID).subscribe((x) => {
                console.log(`getEnterpriseProfile: ${JSON.stringify(x)}`);
                this.setEnterpriseProfile(x);
                this.router.navigate([returnUrl || "/"], { replaceUrl: true });
              });
            }
          },
          (err) => {
            console.log("Unable to fetch user details", err);
            // todo, we may not want to log out here, but instead handle this in a different way?
            this.logout();
          }
        );
      },
      (err) => {
        console.log("completeAuthentication failed", err);
        // todo, handle error
      }
    );
  }

  private setEnterpriseProfile(profile: any) {
    this.EnterpriseProfile = {
      enterpriseID: profile.enterpriseID,
      name: profile.name,
      accountNumber: profile.accountNumber,
      foreignIdentifier: profile.foreignIdentifier,
      enterpriseSettings: {
        enterpriseConfigurationID:
          profile.enterpriseSettings.enterpriseConfigurationID,
        enterpriseId: profile.enterpriseSettings.enterpriseId,
        tmsId: profile.enterpriseSettings.tmsId,
        tmsKey: profile.enterpriseSettings.tmsKey,
        specialServicesEmail: profile.enterpriseSettings.specialServicesEmail,
        specialServicesPhone: profile.enterpriseSettings.specialServicesPhone,
        operationsEmail: profile.enterpriseSettings.operationsEmail,
        operationsPhone: profile.enterpriseSettings.operationsPhone,
        shipmentNotificationEmail:
          profile.enterpriseSettings.shipmentNotificationEmail,
        insuranceCustomerChargeMinValue:
          profile.enterpriseSettings.insuranceCustomerChargeMinValue,
        insuranceCustomerMarkup:
          profile.enterpriseSettings.insuranceCustomerMarkup,
        isEmailInsurance: profile.enterpriseSettings.isEmailInsurance,
        insuranceRequestEmail: profile.enterpriseSettings.insuranceRequestEmail,
        shipmentErrorEmailRecipients:
          profile.enterpriseSettings.shipmentErrorEmailRecipients,
        techErrorEmailRecipients:
          profile.enterpriseSettings.techErrorEmailRecipients,
        legalName: profile.enterpriseSettings.legalName,
        volumeCarrierMinValue: profile.enterpriseSettings.volumeCarrierMinValue,
        truckloadRateMarkupPercent:
          profile.enterpriseSettings.truckloadRateMarkupPercent,
        volumeRateMarkupPercent:
          profile.enterpriseSettings.volumeRateMarkupPercent,
        isOwnFreightDesk: profile.enterpriseSettings.isOwnFreightDesk,
        isEmailTLVolume: profile.enterpriseSettings.isEmailTLVolume,
        isBlueShipBilling: profile.enterpriseSettings.isBlueShipBilling,
        tlVolumeEmail: profile.enterpriseSettings.tlVolumeEmail,
        numberRatesToDisplay: profile.enterpriseSettings.numberRatesToDisplay,
        applyCustomerPriceMarkup:
          profile.enterpriseSettings.applyCustomerPriceMarkup,
        rateRequestNotificationEmail:
          profile.enterpriseSettings.rateRequestNotificationEmail,
        proActiveAuditNumber: profile.enterpriseSettings.proActiveAuditNumber,
        masterBillAccountNumber:
          profile.enterpriseSettings.masterBillAccountNumber,
        isMasterBill: profile.enterpriseSettings.isMasterBill,
        carrierInvoiceVariance:
          profile.enterpriseSettings.carrierInvoiceVariance,
        customerInvoiceThreshold:
          profile.enterpriseSettings.customerInvoiceThreshold,
        mgDownMarkup: profile.enterpriseSettings.mgDownMarkup,
        isBlueShipTender: profile.enterpriseSettings.isBlueShipTender,
        isGeneratePrimaryReference:
          profile.enterpriseSettings.isGeneratePrimaryReference,
        pendingShipmentNotificationEmail:
          profile.enterpriseSettings.pendingShipmentNotificationEmail,
        carrierInvoiceNegativeVariance:
          profile.enterpriseSettings.carrierInvoiceNegativeVariance,
        truckloadManagerEmail: profile.enterpriseSettings.truckloadManagerEmail,
        autoAssignPROStatus: profile.enterpriseSettings.autoAssignPROStatus,
        showCorporateTL: profile.enterpriseSettings.showCorporateTL,
        isHouseAccount: profile.enterpriseSettings.isHouseAccount,
        website: profile.enterpriseSettings.website,
        isWhiteList: profile.enterpriseSettings.isWhiteList,
      },
    };

    this.$enterpriseSelected.next({
      name: profile.name,
      ID: profile.enterpriseID,
    });
  }

  private setBlueshipUser(resp: any) {
    let fullName;

    if (resp.firstName) {
      fullName = `${resp.firstName} ${resp.lastName}`;
    } else {
      fullName = resp.name;
    }

    const selectedID =
      this.getSelectedEnterpriseID(resp.userID) || resp.enterpriseID;

    this.getUserAndEnterpriseOperations(resp.userID, selectedID).subscribe(
      (x) => {
        this.BlueShipUser = {
          userName: resp.userName,
          userID: resp.userID,
          enterpriseID: resp.enterpriseID,
          name: `${fullName}`,
          email: resp.email,
          operations: x,
          selectedEnterpriseID: selectedID,
          userType: resp.userType,
          userAccountNumber: resp.accountNumber!= undefined ? resp.accountNumber : this.BlueShipUser.userAccountNumber,
        };
      }
    );
  }

  getSelectedEnterpriseID(userId): number {
    const storageId = localStorage.getItem(`selectedEnterpriseID-${userId}`);
    const result = storageId ? Number(storageId) : null;
    return result;
  }

  setSelectedEnterpriseId(newEnterpriseId: number) {
    this.BlueShipUser.selectedEnterpriseID = newEnterpriseId;
    localStorage.setItem(
      `selectedEnterpriseID-${this.BlueShipUser.userID}`,
      `${newEnterpriseId}`
    );
    this.setBlueshipUser(this.BlueShipUser);

    this.getEnterpriseProfile(newEnterpriseId).subscribe(
      (x) => {
        this.setEnterpriseProfile(x);
      },
      (err) => {
        alert(err);
      }
    );
  }

  getUserDetails(): Observable<any> {
    return this.http
      .get<any>(`${this.startupService.shipmentApiUrl}v1/user/current`, {
        headers: this.getHeaders(),
      })
      .retry(2);
  }

  public getHeaders(): HttpHeaders {
    const userName = this.authUser.profile.name;
    const headers = new HttpHeaders({
      UserName: userName ? userName : null,
    });
    return headers;
  }

  get authority(): string {
    return this.manager.settings.authority;
  }

  public get BlueShipUser(): BlueShip.User {
    const userString = sessionStorage.getItem("BlueShipUser");

    if (userString) {
      const u = {} as BlueShip.User;
      const u2: BlueShip.User = Object.assign(u, JSON.parse(userString));

      return u2;
    } else {
      return null;
    }
  }

  getEnterpriseProfile(enterpriseID): Observable<any> {
    return this.http.get<any>(
      `${this.startupService.shipmentApiUrl}v1/enterprise/${enterpriseID}`
    );
  }

  public get EnterpriseProfile(): Enterprise.Profile {
    const enterpriseString = sessionStorage.getItem("EnterpriseProfile");

    if (enterpriseString) {
      const u = {} as Enterprise.Profile;
      const u2: Enterprise.Profile = Object.assign(
        u,
        JSON.parse(enterpriseString)
      );

      return u2;
    } else {
      return null;
    }
  }

  getUserAndEnterpriseOperations(userId, enterpriseId): Observable<any> {
    return this.http.post<any>(
      `${this.startup.shipmentApiUrl}v1/user/operations`,
      { EnterpriseID: enterpriseId, UserID: userId }
    );
  }

  public set EnterpriseProfile(value: Enterprise.Profile) {
    if (value) {
      sessionStorage.setItem("EnterpriseProfile", JSON.stringify(value));
    } else {
      sessionStorage.removeItem("EnterpriseProfile");
    }
  }

  public get Branding(): BlueShip.Branding {
    const brandingString = sessionStorage.getItem("EnterpriseBranding");

    if (brandingString) {
      const b = {} as BlueShip.Branding;
      const b2: BlueShip.Branding = Object.assign(
        b,
        JSON.parse(brandingString)
      );

      return b2;
    } else {
      return null;
    }
  }

  public set Branding(value: BlueShip.Branding) {
    if (value) {
      sessionStorage.setItem("EnterpriseBranding", JSON.stringify(value));
    } else {
      sessionStorage.removeItem("EnterpriseBranding");
    }
  }

  public set BlueShipUser(value: BlueShip.User) {
    if (value) {
      sessionStorage.setItem("BlueShipUser", JSON.stringify(value));
    } else {
      sessionStorage.removeItem("BlueShipUser");
    }
  }

  public set authUser(value: User) {
    if (value) {
      const tokenTime = new Date(0);
      tokenTime.setUTCSeconds(value.expires_at);
      this._tokenTime = tokenTime;
      sessionStorage.setItem("user", value.toStorageString());
    } else {
      sessionStorage.removeItem("user");
    }
  }

  public get authUser(): User {
    // todo, using sessionStorage is currently only because auth-guard runs before user is retreived
    // There's a better way to do this.

    const userString = sessionStorage.getItem("user");

    if (userString) {
      return <User>Object.assign({}, JSON.parse(userString));
    } else {
      return null;
    }
  }

  logout() {
    console.log(baseUrl);
    this.manager
      .signoutRedirect({
        post_logout_redirect_uri: baseUrl,
      })
      .then(
        () => {
          localStorage.removeItem(
            `selectedEnterpriseID-${this.BlueShipUser.userID}`
          );
          this.authUser = null;
          this.BlueShipUser = null;
          this.Branding = null;
        },
        (err) => {
          console.log("signoutRedirect failed", err);
        }
      );
    // .catch() ?
  }

  setAccessTime() {
    const accessTime = new Date();
    accessTime.setUTCHours(accessTime.getUTCHours() + 6);
    this._accessTime = accessTime;
    sessionStorage.setItem("access_time", JSON.stringify(this._accessTime));
  }

  updateToken() {
    if (this._tokenTime < this._accessTime) {
      this.manager
        .signinSilent()
        .then((user) => {
          this.authUser = user;
          const date = new Date(0);
          date.setUTCSeconds(this.authUser.expires_at);
          this._tokenTime = date;
        })
        .catch(() => {
          this.manager.getUser().then((user) => {
            this.authUser = user;
            const date = new Date(0);
            date.setUTCSeconds(this.authUser.expires_at);
            this._tokenTime = date;
          });
        });
    }
  }
}
