import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { map } from "rxjs/operators";
import "rxjs/add/operator/toPromise";
import { overrideConfigFromLocalStorage } from "@bluegrace/bs.npmcommon";

type TStartupData = {
  authApiUrl: string;
  environment: string;
  ratingApiUrl: string;
  shipmentApi: string;
  // feature flags:
  enableFTLRates: string;
  enableFuelDay: string;
};

@Injectable({
  providedIn: "root",
})
export class StartupService {
  // To override the configs below, go to the browser console and run "bgEnvConfigOverrides.enable()"
  // This will enable the EnvConfigOverride UI at the bottom right corner of the screen
  private _startupData: TStartupData = {
    authApiUrl: "https://identity-qa.myblueship.com/",
    environment: "dev",
    ratingApiUrl: "https://rating-qa.myblueship.com",
    shipmentApi: "https://shipmentapi-qa.myblueship.com/api/",
    // feature flags:
    enableFTLRates: "true",
    enableFuelDay: "true"
  };

  constructor(private http: Http) {}

  load(): Promise<TStartupData> {
    return this.http
      .get("/config")
      .pipe(map((res) => res.json()))
      .toPromise()
      .catch(() => Promise.resolve(this._startupData))
      .then((data: TStartupData) => {
        this._startupData = overrideConfigFromLocalStorage(
          data,
          ["environment"],
          "Bluelight"
        );
        console.log("startupData", this._startupData);
        return this._startupData;
      });
  }

  get startupData() {
    return this._startupData;
  }
  get ratingApiUrl() {
    return this._startupData.ratingApiUrl;
  }
  get shipmentApiUrl() {
    return this._startupData.shipmentApi;
  }
  get authAPIUrl() {
    return this._startupData.authApiUrl;
  }
  get appEnvironment() {
    return this._startupData.environment;
  }
  // feature flags:
  get enableFTLRates() {
    return JSON.parse(this._startupData.enableFTLRates);
  }
  get enableFuelDay() {
    return JSON.parse(this._startupData.enableFuelDay);
  }
}
