import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { StartupService } from "./services/startup.service";
import { LoadingService } from "./services/loading.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "bgratingweb";
  isLoading = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private startupService: StartupService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.loadingService.subscribe((isLoading) => {
      this.isLoading = isLoading;
      // using detectChanges to avoid 'ExpressionChangedAfterItHasBeenCheckedError' issues
      this.cdr.detectChanges();
  });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRouteData.state : "";
  }

  get showHeader(): boolean {
    return (
      this.authService.BlueShipUser != null &&
      this.authService.EnterpriseProfile != null
    );
  }
}
