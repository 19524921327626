import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      this.authService.showHeader = true;

      if(!this.authService.BlueShipUser){
        this.authService.startAuthentication(state.url) }
      if(this.authService.BlueShipUser.userType && this.authService.BlueShipUser.userType === 'Customer'){
        this.router.navigate(["/redirect"]);
        return false;
      }
      return true;
    }

    this.authService.showHeader = false;
    this.authService.startAuthentication(state.url);
    return false;
  }
}
